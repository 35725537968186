<template>
  <div class="login">
    <div class="login_bos_title">
      完善个人信息
    </div>

    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top" style="width: 80%">
      <el-row >
        <el-col :span="6">
<!--          <div class="custom-label">-->
<!--            <el-form-item label="上传头像" prop="avatar_file" style="color: #fff;">-->
<!--              <el-upload :show-file-list="false" class="avatar-uploader"-->
<!--                         :action="base_url + 'api/common/uploadOBS'"-->
<!--                         :on-success="handleAvatarSuccess"-->
<!--                         :before-upload="beforeAvatarUpload" :headers="{token:token}">-->
<!--                  <img v-if="ruleForm.avatar_file" :src="avatar_img" class="avatar" style="object-fit: cover;"/>-->
<!--                <el-icon v-else class="avatar-uploader-icon">-->
<!--                  <Plus/>-->
<!--                </el-icon>-->
<!--              </el-upload>-->
<!--                <view v-if="avatar_img_history_type === 2" style="width: 178px;height: 178px;">-->
<!--                    <span> 历史头像(点击使用历史头像) </span>-->
<!--                    <img v-if="ruleForm.avatar_file" :src="avatar_img_history" class="avatar" style="object-fit: cover;" @click="replace(avatar_img_history)"/>-->
<!--                </view>-->
<!--            </el-form-item>-->
<!--          </div>-->
          <div class="custom-label">
            <el-form-item label="上传名片或工牌" prop="user_image_file">
              <el-upload :show-file-list="false" class="avatar-uploader mysign"
                         :action="base_url + 'api/common/uploadOBS'"
                         :on-success="handleUserImageFileSuccess"
                         :before-upload="beforeUserImageFileUpload" :headers="{token:token}">
                <img v-if="ruleForm.user_image_file" :src="user_img" class="avatar"/>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus/>
                </el-icon>
              </el-upload>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="18">
          <el-row>
            <el-col>
                <div style="display: flex;align-items: center;">
                    <div style="width:100%;">
                        <el-form-item label="昵称" prop="nickname">
                            <el-input v-model="ruleForm.nickname" @blur="checking" @focus="checking" @change="checking"/>
                        </el-form-item>
                    </div>
                    <div style="color: red;border: 1px solid red;padding: 2px;margin: 10px 0 0 10px;width:70px;text-align: center;">可修改</div>
                </div>
            </el-col>
            <el-col>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="ruleForm.name"/>
              </el-form-item>
            </el-col>
<!--            <el-col>-->
<!--              <el-form-item label="邀请码" prop="invitation_code">-->
<!--                <el-input v-model="ruleForm.invitation_code"/>-->
<!--              </el-form-item>-->
<!--              <div style="font-size: 12px;font-weight: bold;color: #FFFFFF;margin-bottom:10px;">-->
<!--                如无邀请码请请致电{{tel}}（微信同号）-->
<!--              </div>-->
<!--            </el-col>-->
            <el-col>
                <el-form-item label="登录密码" prop="password" v-if="usertype == 1">
                    <el-input v-model="ruleForm.password" placeholder="最少6位密码" />
                </el-form-item>
                <el-form-item label="登录密码" prop=""  v-else>
                    <el-input v-model="ruleForm.password" placeholder="最少6位密码" />
                </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="ruleForm.mobile" disabled/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
<!--          <el-form-item label="上传名片或工牌" prop="user_image_file">-->
<!--            <el-upload :show-file-list="false" class="avatar-uploader mysign"-->
<!--                       :action="base_url + 'api/common/uploadOBS'"-->
<!--                       :on-success="handleUserImageFileSuccess"-->
<!--                       :before-upload="beforeUserImageFileUpload" :headers="{token:token}">-->
<!--              <img v-if="ruleForm.user_image_file" :src="user_img" class="avatar"/>-->
<!--              <el-icon v-else class="avatar-uploader-icon">-->
<!--                <Plus/>-->
<!--              </el-icon>-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
        </el-col>
        <el-col :span="18">
          <el-row :gutter="20">
<!--            <el-col>-->
<!--              <el-form-item label="岗位或职位" prop="position">-->
<!--                <el-input v-model="ruleForm.position"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col>
              <el-form-item label="单位" prop="employer">
                <el-input v-model="ruleForm.employer"/>
              </el-form-item>
              <div style="font-size: 12px;font-weight: bold;color: #FFFFFF;margin-bottom:10px;">如有疑问请致电{{tel}}(微信同号)</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"></el-col>
        <el-col>
          <el-button style="width: 100%;margin-bottom:20px;" type="primary" @click="submitForm(ruleFormRef,usertype)">
            提交
          </el-button>
        </el-col>
        <el-col v-if="usertype == 2">
          <el-button style="width: 100%;" type="primary" @click="gohome">
            取消
          </el-button>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<script setup scoped>
import {onMounted, reactive, ref, onBeforeMount } from 'vue'
import {useStore} from 'vuex'
import request from '@/api/request'
import {Plus} from '@element-plus/icons-vue'
import {updateUser, getUserDetails, systemConfig, transitionInvitationCode, isExistsUserNickname, logout} from "@/api";
import {ElMessage} from "element-plus";
import {useRouter,useRoute} from "vue-router";
let userinfo = ref([])
let avatar_img = ref('')
let avatar_img_history = ref('')
let avatar_img_history_type = ref(1)
let user_img = ref('')
let usertype = ref(1)
let keyValue = ref(1)
let tel = ref('')
const router = useRouter()
const route = useRoute()
const store = useStore()
const base_url = request.BASEURL
const ruleFormRef = ref(null)
const token = store.state.token
const ruleForm = reactive({
  nickname: '',
  avatar_file: '',
  invitation_code: '',
  employer: '',
  position: '',
  user_image_file: '',
  name: '',
  avatar: '',
  verification: '',
  mobile: '',
})

const rules = reactive({
  nickname: [
      {required: true, message: '请填写昵称', trigger: 'blur'}
  ],
  password: [
      {required: true, message: '请填写密码', trigger: 'blur'}
  ],
  name: [
      {required: true, message: '请填写姓名', trigger: 'blur'}
  ],
  position: [
      {required: true, message: '请填写岗位或职位', trigger: 'blur'}
  ],
  employer: [
      {required: true, message: '请填写单位或部门', trigger: 'blur'}
  ],
  user_image_file: [
      {required: true, message: '请填写上传名片或工牌', trigger: 'blur'}
  ]
})
const gohome = () => {
  router.push({path: '/index'})
}
const submitForm = async (formEl,usertype) => {
  const is_required_userinfo = localStorage.getItem('is_required_userinfo');
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
        if(usertype == 0){
            ruleForm['type'] = 0
        }else{
            ruleForm['type'] = 1
        }
      updateUser(ruleForm).then(res => {
        if (res.code === 1) {
          if(is_required_userinfo == 1){
            ElMessage.success({message: '注册成功,等待审核!'})
            logout()
                .then(res => {
                  store.commit('empty')
                  router.push('/')
                  document.querySelector('body').classList.remove('no-scroll');
                })
          }else{
            ElMessage.success({message: '更新成功'})
            router.push({path: '/index'})
          }

        }
      })
    }
  })
}

//验证用户昵称
const checking = () => {
    if(ruleForm.nickname !== ''){
        isExistsUserNickname({
            nickname: ruleForm.nickname
        }).then(res=>{
            console.log(res)
        })
    }
}


// const getUserinfo = () => {
//     getUserDetails().then(res => {
//         if (res.code === 1){
//             userinfo.value = res.data
//             ruleForm.nickname = userinfo.value.nickname
//             // ruleForm.invitation_code = userinfo.value.invitation_code
//             ruleForm.position = userinfo.value.position
//             ruleForm.employer = userinfo.value.employer
//             ruleForm.name = userinfo.value.name
//         }
//     })
// }


const handleAvatarSuccess = (uploadFile, uploadFiles) => {
  if (uploadFile.code === 1) {
    ruleForm.avatar_file = uploadFile.data.url
    avatar_img.value = uploadFile.data.fullurl
    avatar_img_history_type.value = 2
  }
}

const replace = (url) => {
    console.log(url)
    ruleForm.avatar_file = url
    avatar_img.value = url
    ElMessage.success({message: '更换成功'})
}

const beforeAvatarUpload = (response, file) => {
}

const handleUserImageFileSuccess = (uploadFile, uploadFiles) => {
  if (uploadFile.code === 1) {
    ruleForm.user_image_file = uploadFile.data.url
    user_img.value = uploadFile.data.fullurl
  }
}

const beforeUserImageFileUpload = (file) => {
}

const getconfig = () => {
    systemConfig().then(res => {
        if (res.code === 1){
            tel.value = res.data.web_service_tel
        }
    })
}
const gettype = () => {
    usertype.value = route.query.type
}

//获取邀请码key
const invitationKey = () => {
    const url = window.location.href;
    const params = new URLSearchParams(url.split('?')[1]); // 获取问号后面的参数部分
    keyValue.value = params.get('key');
    if (keyValue.value){
        transitionInvitationCode({invitation_code_key:keyValue.value}).then(res => {
            if (res.code === 1){
                ruleForm.invitation_code = res.data.invitation_code
            }
        })
    }else{
        getUserDetails().then(res => {
        if (res.code === 1){
            ruleForm.avatar = userinfo.value.avatar
            userinfo.value = res.data
            ruleForm.nickname = userinfo.value.nickname
            ruleForm.invitation_code = userinfo.value.invitation_code
            ruleForm.position = userinfo.value.position
            ruleForm.employer = userinfo.value.employer
            ruleForm.name = userinfo.value.name
            ruleForm.password = userinfo.value.password
            ruleForm.user_image_file = userinfo.value.user_image
            user_img.value = userinfo.value.user_image
            ruleForm.avatar_file = userinfo.value.avatar
            avatar_img.value = userinfo.value.avatar
            avatar_img_history.value = userinfo.value.avatar
            ruleForm.mobile = userinfo.value.mobile
        }
    })
    }
}

const updateTitleAndFavicon = () => {
    document.title = '复盘';
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
        favicon.href = 'https://video-source.obs.cn-north-4.myhuaweicloud.com/img/default_user.jpg';
    }
}

onMounted(() => {
    // getUserinfo()
    invitationKey()
    getconfig()
    gettype()
    updateTitleAndFavicon()
})
</script>

<style lang="scss">
.el-form-item__label {
  color: #FFFFFF !important;
  /* 其他样式属性... */
}

.avatar-uploader {
  width: 178px;
  height: 178px;
  display: block;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}
.mysign{
  width:240px;
  .el-icon.avatar-uploader-icon {
    width: 240px;
  }
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 178px;
  text-align: center;
  border-color: var(--el-color-primary);
}
.avatar{
  width: 100%;
  object-fit: contain;
  display: block;
}
.login {
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #0d2e64;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;

  .login_bos_title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 40px;
  }
}
</style>
