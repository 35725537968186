<template>
	<div
		v-loading="loading"
		element-loading-text="文件正在上传中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)"
		class="loading-element"
	>
		<div class="layout">
			<!-- 头部背景图片 -->
			<div class="layout_bg"></div>
			<div class="layout_content">
				<!-- 头部信息 -->
				<div
					class="layout_content_head"
					style="
						display: flex;
						align-items: center;
						justify-content: space-between;
					"
				>
					<div class="layout_content_head_day">
						<div style="font-size: 28px">
							{{ getCurrentDate() }}
						</div>

						<div
							style="
								width: 200px;
								padding-top: 5px;
								display: flex;
								align-items: center;
							"
						>
							<div style="font-size: 13px">
								{{ getsolarToLunar() }}
							</div>
							<div style="font-size: 13px; margin-left: 5px">
								{{ getweekstr() }}
							</div>
						</div>
					</div>
					<div class="layout_content_head_banner">
						<el-carousel
							style="height: 69px; width: 100%"
							autoplay
							arrow="never"
						>
							<el-carousel-item
								v-for="(item, index) in bannerList"
								:key="index"
							>
								<img
									@click="jumpBanner(item)"
									:src="item.smallimage"
									alt="banner"
									class="resize-image"
								/>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="layout_content_head_user" @click="openUser">
						<div class="user_img">
							<img :src="user.avatar" alt="avatar" />
						</div>
						<div class="user_info">
							<span>{{ user.nickname }}</span>
							<div class="user_info_ststus">
								<img
									style="width: 18px; height: 18px"
									src="@/assets/iconPark-vip3x@2x.png"
									alt=""
									v-if="user.is_period_of_validity == 1"
								/>
								<img
									style="width: 18px; height: 18px"
									src="@/assets/huiv.png"
									alt=""
									v-if="user.is_period_of_validity == 0"
								/>
								<div class="user_info_ststus_border">
									<span>已认证</span>
								</div>
								<!--              <el-popover-->
								<!--                  :width="150"-->
								<!--                  trigger="hover">-->
								<!--                <template #reference>-->
								<!--                  <div class="fenxiang-box">-->
								<!--                    <div class="fenxiangbtn">-->
								<!--                      分享-->
								<!--                    </div>-->
								<!--                  </div>-->
								<!--                </template>-->
								<!--                <div style="width: 100%; display: flex; align-items: center;">-->
								<!--                  <div @click="shareWeChat(Invitation)"-->
								<!--                       style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
								<!--                    <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>-->
								<!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
								<!--                  </div>-->
								<!--                  <div @click="shareQQ(Invitation)"-->
								<!--                       style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
								<!--                      <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>-->
								<!--                      <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
								<!--                  </div>-->
								<!--                  <div @click="shareMicroBlog(Invitation)"-->
								<!--                       style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
								<!--                    <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>-->
								<!--                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
								<!--                  </div>-->
								<!--                </div>-->
								<!--              </el-popover>-->
							</div>
							<div>
								<!-- <span style="font-size: 12px"
									>邀请码：{{ user.invitation_code }}</span
								> -->
							</div>
						</div>
					</div>
				</div>
				<!-- 图标数据 -->
				<!--      <div class="layout_content_picture">-->
				<!--        <div class="layout_content_picture_item">-->
				<!--          <div class="layout_content_picture_item_head">-->
				<!--            <div class="layout_content_picture_item_head_title">-->
				<!--              <img src="@/assets/jian@2x.png" alt=""/>-->
				<!--              <span v-if="threeName.length >= 1">{{ threeName[0].name }}</span>-->
				<!--            </div>-->
				<!--            <div style="display: flex; align-items: center;">-->
				<!--            </div>-->
				<!--          </div>-->
				<!--          <div class="layout_content_picture_item_tu" :style="{ 'filter': xian1 === 0 ? 'blur(5px) contrast(150%)' : '' }">-->
				<!--            <lineElement id="line1" ref="LineElement1"/>-->
				<!--          </div>-->
				<!--        </div>-->
				<!--        <div class="layout_content_picture_item">-->
				<!--          <div class="layout_content_picture_item_head">-->
				<!--            <div class="layout_content_picture_item_head_title">-->
				<!--              <img src="@/assets/jian@2x.png" alt=""/>-->
				<!--              <span v-if="threeName.length >= 2">{{ threeName[1].name }}</span>-->
				<!--            </div>-->
				<!--            <div style="display: flex; align-items: center;">-->
				<!--            </div>-->
				<!--          </div>-->
				<!--          <div class="layout_content_picture_item_tu" :style="{ 'filter': xian2 === 0 ? 'blur(5px) contrast(150%)' : '' }">-->
				<!--            <lineElement id="line2" ref="LineElement2"/>-->
				<!--          </div>-->

				<!--        </div>-->
				<!--        <div class="layout_content_picture_item">-->
				<!--          <div class="layout_content_picture_item_head">-->
				<!--            <div class="layout_content_picture_item_head_title">-->
				<!--              <img src="@/assets/jian@2x.png" alt=""/>-->
				<!--              <span v-if="threeName.length >= 3">{{ threeName[2].name }}</span>-->
				<!--            </div>-->
				<!--            <div style="display: flex; align-items: center;">-->
				<!--            </div>-->
				<!--          </div>-->
				<!--          <div class="layout_content_picture_item_tu" :style="{ 'filter': xian3 === 0 ? 'blur(5px) contrast(150%)' : '' }">-->
				<!--            <lineElement id="line3" ref="LineElement3"/>-->
				<!--          </div>-->
				<!--        </div>-->
				<!--      </div>-->
				<!-- 主体内容区域 -->
				<div class="layout_content_box">
					<!-- 菜单栏 -->
					<div class="layout_content_box_menu">
						<div class="menu_logo" style="cursor: pointer">
							<img
								:src="sysconfig.web_logo"
								alt=""
								@click="goshouyeguangchang"
							/>
						</div>
						<div class="menu_top">
							<div
								class="menu_top_item"
								style="z-index: 9997"
								v-for="(item, index) in menu"
								:key="index"
							>
								<!--                @click="item.children.length == 0 ? clickItem(item.id, item.name, item.id,1) : ''">-->
								<div @click.stop="clickItem1(item.id, item.name, item.id)">
									<span class="menu_top_item_text">{{ item.name }}</span>
									<div
										class="menu_top_item_list"
										v-if="item.children.length > 0"
									>
										<div
											class="menu_top_item_list_item"
											v-for="(child, i) in item.children"
											:key="i"
											@click.stop="clickItem( child.id, `${item.name} - ${child.name}`, item.id)"
										>
											<span
												class="menu_top_item_list_item_text"
												style="color: #f8991e"
												>{{ child.name }}</span
											>
											<div
												class="menu_top_item_list_list"
												v-if="child.children.length > 0"
												style="z-index: 9999"
											>
												<div
													class="menu_top_item_list_list_item"
													v-for="(child1, ii) in child.children"
													:key="ii"
													@click.stop="clickItem(child.id,`${item.name} - ${child.name} - ${child1.name}`,item.id)">
													<span class="menu_top_item_list_list_item_text">{{child1.name}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 内容区域 -->
					<div class="layout_content_box_nr">
						<!-- 输入框 -->
						<template v-if="typeView == 1 || typeView == 3 || typeView == 5">
							<textareaComponent
								:typeView="typeView"
								:channel="childMenuId"
								:inputtype="inputtype"
								@result="sendMessageResult"
								@upMask="upMask"
							/>
						</template>
						<!-- 论坛/新闻 -->
						<template
							v-if="
								(typeView == 1 || typeView == 3 || typeView == 5) && childMenuId
							"
						>
							<stickView
								:channel="childMenuId"
								:typeView="typeView"
								:create_time="dateYMD"
								ref="stickRef"
								:timeVal="timeVla"
								:title="title"
								:title1="title1"
								:typeInput="inputtype"
								@finish="reset"
								@openPreview="handleShow"
								@updateContent="updateContent"
							/>
						</template>
						<!-- 投研日志 -->
						<template v-if="typeView == 7">
							<logView
								:title="title"
								:title1="title1"
								:channel="childMenuId"
								:create_time="dateYMD"
								@openPreview="handleShow"
								ref="logRef"
							/>
						</template>
						<!-- 视频 -->
						<template v-if="typeView == 4">
							<VideoView
								:title="title"
								:title1="title1"
								:channel="childMenuId"
								:create_time="dateYMD"
								ref="videoRef"
								:timeVal="timeVla"
								@openPreview="handleShow"
								@finish="reset"
							/>
						</template>
						<!-- 直播 -->
						<template v-if="typeView == 6">
							<LiveBroadcast
								:title="title"
								:title1="title1"
								:channel="childMenuId"
								:create_time="dateYMD"
								ref="broadcastRef"
								:timeVal="timeVla"
								@finish="reset"
							/>
						</template>
						<!-- 量化信号 -->
						<template v-if="typeView == 2">
							<chartList
								:title="title"
								:title1="title1"
								:timeVal="timeVla"
								:channel="childMenuId"
								@finish="reset"
								@openPreview="handleShow"
								ref="quantization"
							/>
						</template>

						<template v-if="typeView == 9">
							<searchList
								:key="searchListKey"
								:datetime="dateYMD"
								:con="keyword"
								:title="title"
								:timeVal="timeVla"
								:channel="childMenuId"
								@finish="reset"
								ref="searchinfo"
								@openPreview="handleShow"
								@updateContent="updateContent"
							/>
						</template>
					</div>
					<div class="layout_content_box_right">
						<div
							class="right_day"
							style="
								height: 80vh;
								max-height: 900px;
								position: relative;
								z-index: 1;
								overflow-x: auto;
							"
							v-if="customer_dialog_div"
						>
							<div class="right_day_head">
								<div class="right_day_head_left">
									<div
										style="display: flex; align-items: center; cursor: pointer"
									>
										<img src="@/assets/jian@2x.png" alt="" />
										<span>聊天室</span>
									</div>
								</div>
							</div>
							<div style="width: 100%; height: calc(100% - 23px); overflow-y: hidden;">
								<iframe
									:src="imurl_div"
									style="
										width: 100%;
										height: 100%;
										border-radius: 10px;
										background: #fff;
										outline: none;
										border: none;
									"
								></iframe>
							</div>
						</div>
						<!-- 日历 -->
						<div class="right_day" style="z-index: 1">
							<div class="right_day_head">
								<div class="right_day_head_left">
									<div
										style="display: flex; align-items: center; cursor: pointer"
									>
										<img src="@/assets/jian@2x.png" alt="" />
										<span>检索</span>
									</div>
								</div>
							</div>
							<div class="right_day_head_sou">
								<input
									type="text"
									v-model="keyword"
									style="color: #000000; width: 100%"
									placeholder="请输入内容关键字"
									@keyup.enter="keywordFc"
								/>
								<img
									src="@/assets/sou2x1.png"
									style="width: 25px; height: 25px"
									@click="keywordFc"
									alt=""
								/>
							</div>
							<div class="right_day_head" style="margin-top: 15px">
								<div class="right_day_head_left">
									<div
										style="display: flex; align-items: center; cursor: pointer"
									>
										<img src="@/assets/jian@2x.png" alt="" />
										<span>我的足迹</span>
									</div>
								</div>
							</div>
							<div class="right_day_box">
								<Calendar
									:selectedDate="selectedDate"
									:activeList="activeList"
									@update:change="changeDate"
									@update:selectedDate="onSelectedDateUpdated"
								/>
							</div>
						</div>
						<!-- 直播预告 -->
						<div class="right_day">
							<div class="right_day_head">
								<div class="right_day_head_left">
									<div
										style="display: flex; align-items: center; cursor: pointer"
									>
										<img src="@/assets/jian@2x.png" alt="" />
										<span>{{ sysconfig.web_sidebar_as_1 }}</span>
									</div>
									<!--                          <div style="display: flex;align-items: center;cursor: pointer;">-->
									<!--                              <span>更多</span>-->
									<!--                              <img style="width: 30px;height: 30px;" src="@/assets/gengduo.png" alt=""/>-->
									<!--                          </div>-->
								</div>
							</div>
							<div
								class="right_day_box1"
								v-if="liveobj && liveobj.live_room"
								style="cursor: pointer"
							>
								<div class="right_day_box1_left">
									<img :src="liveobj.live_room.image" alt="" />
								</div>
								<div
									class="right_day_box1_right"
									style="
										display: flex;
										align-items: center;
										justify-content: center;
									"
								>
									<div class="right_day_box1_right_one">
										{{ liveobj.live_room.title }}
									</div>
									<div class="right_day_box1_right_two">
										{{ liveobj.live_room.description }}
									</div>
									<!--                <div class="right_day_box1_right_three" @click="jumpLive(liveobj.live_room)">进入直播间</div>-->
								</div>
							</div>
							<div class="right_day_box1" v-else>暂未有任何直播内容</div>
						</div>

						<div v-for="(item, index) in getSidebar_list" :key="index">
							<div class="right_day" style="margin-bottom: 20px">
								<div class="right_day_head">
									<div class="right_day_head_left">
										<div
											style="
												display: flex;
												align-items: center;
												cursor: pointer;
											"
										>
											<img src="@/assets/jian@2x.png" alt="" />
											<span>{{ item.as_name }}</span>
										</div>
										<div
											style="
												display: flex;
												align-items: center;
												cursor: pointer;
											"
											@click="gossip_list(item)"
										>
											<span>更多</span>
											<img
												style="width: 30px; height: 30px"
												src="@/assets/gengduo.png"
												alt=""
											/>
										</div>
									</div>
								</div>
								<div
									v-if="item && item.list.length > 0"
									style="cursor: pointer"
								>
									<div class="right_day_box2" @click="jump(item.list[0])">
										<div class="right_day_box2_top">
											<div class="right_day_box1_left">
												<img :src="item.list[0].image" alt="" />
											</div>
											<div class="right_day_box1_right">
												<div class="right_day_box1_right_info">
													<div class="right_day_box1_right_one">
														{{ item.list[0].title }}
													</div>
													<div class="right_day_box1_right_two">
														{{ item.name }}
													</div>
												</div>
												<div class="right_day_box1_right_play">
													<img src="@/assets/play.png" alt="" />
												</div>
											</div>
										</div>
									</div>
									<div class="right_day_x"></div>
									<div
										class="right_day_list"
										v-if="dailyliveobj_list.length > 0"
									>
										<div
											class="right_day_list_item"
											v-for="(item1, index1) in item.list"
											:key="index1"
											@click="jump(item1)"
										>
											<div class="right_day_list_item_tps">【日更】</div>
											<div class="right_day_list_item_wz">
												{{ item1.title }}
											</div>
											<img
												class="right_day_list_item_play"
												src="@/assets/play1.png"
												alt=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!--              &lt;!&ndash; 每日视频 &ndash;&gt;-->
						<!--              <div class="right_day">-->
						<!--                <div class="right_day_head">-->
						<!--                  <div class="right_day_head_left">-->
						<!--                      <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                          <img src="@/assets/jian@2x.png" alt=""/>-->
						<!--                          <span>{{ sysconfig.web_sidebar_as_2 }}</span>-->
						<!--                      </div>-->
						<!--                      <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                          <span>更多</span>-->
						<!--                          <img style="width: 30px;height: 30px;" src="@/assets/gengduo.png" alt=""/>-->
						<!--                      </div>-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--                <div v-if="dailyliveobj_top && dailyliveobj_list.length > 0" style="cursor: pointer">-->
						<!--                  <div class="right_day_box2"-->
						<!--                       @click="jump(dailyliveobj_top.id,dailyliveobj_top.is_member_expire,dailyliveobj_top)">-->
						<!--                    <div class="right_day_box2_top">-->
						<!--                      <div class="right_day_box1_left">-->
						<!--                        <img :src="dailyliveobj_top.image" alt=""/>-->
						<!--                      </div>-->
						<!--                      <div class="right_day_box1_right">-->
						<!--                        <div class="right_day_box1_right_info">-->
						<!--                          <div class="right_day_box1_right_one">-->
						<!--                            {{ dailyliveobj_top.title }}-->
						<!--                          </div>-->
						<!--                          <div class="right_day_box1_right_two">-->
						<!--                            {{ dailyliveobj_top.category_name }}-->
						<!--                          </div>-->
						<!--                        </div>-->
						<!--                        <div class="right_day_box1_right_play">-->
						<!--                          <img src="@/assets/play.png" alt=""/>-->
						<!--                        </div>-->
						<!--                      </div>-->
						<!--                    </div>-->
						<!--                  </div>-->
						<!--                  <div class="right_day_x"></div>-->
						<!--                  <div class="right_day_list" v-if="dailyliveobj_list.length>0">-->
						<!--                    <div class="right_day_list_item" v-for="(item, index) in dailyliveobj_list.slice(0, 5)" :key="index"-->
						<!--                         @click="jump(item.id,item.is_member_expire,item)">-->
						<!--                      <div class="right_day_list_item_tps">-->
						<!--                        【日更】-->
						<!--                      </div>-->
						<!--                      <div class="right_day_list_item_wz">-->
						<!--                        {{ item.title }}-->
						<!--                      </div>-->
						<!--                      <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">-->
						<!--                    </div>-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--              </div>-->
						<!--              &lt;!&ndash; 每周视频 &ndash;&gt;-->
						<!--              <div class="right_day">-->
						<!--                <div class="right_day_head">-->
						<!--                  <div class="right_day_head_left">-->
						<!--                      <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                          <img src="@/assets/jian@2x.png" alt=""/>-->
						<!--                          <span>{{ sysconfig.web_sidebar_as_3 }}</span>-->
						<!--                      </div>-->
						<!--                      <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                          <span>更多</span>-->
						<!--                          <img style="width: 30px;height: 30px;" src="@/assets/gengduo.png" alt=""/>-->
						<!--                      </div>-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--                <div>-->
						<!--                  <div class="right_day_box2" style="cursor: pointer">-->
						<!--                    <div class="right_day_box2_top"-->
						<!--                         @click="jump(weeklyliveobj_top.id,weeklyliveobj_top.is_member_expire,weeklyliveobj_top)"-->
						<!--                         v-if="weeklyliveobj_top.title">-->
						<!--                      <div class="right_day_box1_left">-->
						<!--                        <img :src="weeklyliveobj_top.image" alt=""/>-->
						<!--                      </div>-->
						<!--                      <div class="right_day_box1_right">-->
						<!--                        <div class="right_day_box1_right_info">-->
						<!--                          <div class="right_day_box1_right_one">-->
						<!--                            {{ weeklyliveobj_top.title }}-->
						<!--                          </div>-->
						<!--                          <div class="right_day_box1_right_two">-->
						<!--                            {{ weeklyliveobj_top.category_name }}-->
						<!--                          </div>-->
						<!--                        </div>-->
						<!--                        <div class="right_day_box1_right_play">-->
						<!--                          <img src="@/assets/play2.png" alt=""/>-->
						<!--                        </div>-->
						<!--                      </div>-->
						<!--                    </div>-->
						<!--                  </div>-->
						<!--                  <div class="right_day_x"></div>-->
						<!--                  <div class="right_day_list" v-if="weeklyliveobj_list.length>0">-->
						<!--                    <div class="right_day_list_item" v-for="(item, index) in weeklyliveobj_list.slice(0, 5)" :key="index"-->
						<!--                         @click="jump(item.id,item.is_member_expire,item)">-->
						<!--                      <div class="right_day_list_item_tps">-->
						<!--                        【日更】-->
						<!--                      </div>-->
						<!--                      <div class="right_day_list_item_wz">-->
						<!--                        {{ item.title }}-->
						<!--                      </div>-->
						<!--                      <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">-->
						<!--                    </div>-->
						<!--                  </div>-->
						<!--                </div>-->

						<!--              </div>-->
						<!--              &lt;!&ndash; 每月视频 &ndash;&gt;-->
						<!--              <div class="right_day">-->
						<!--                <div class="right_day_head">-->
						<!--                  <div class="right_day_head_left">-->
						<!--                      <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                          <img src="@/assets/jian@2x.png" alt=""/>-->
						<!--                          <span>{{ sysconfig.web_sidebar_as_4 }}</span>-->
						<!--                      </div>-->
						<!--                      <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                          <span>更多</span>-->
						<!--                          <img style="width: 30px;height: 30px;" src="@/assets/gengduo.png" alt=""/>-->
						<!--                      </div>-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--                <div v-if="recommendliveobj_top && recommendliveobj_list.length > 0">-->
						<!--                  <div class="right_day_box2" style="cursor: pointer">-->
						<!--                    <div class="right_day_box2_top"-->
						<!--                         @click="jump(recommendliveobj_top.id,recommendliveobj_top.is_member_expire,recommendliveobj_top)">-->
						<!--                      <div class="right_day_box1_left">-->
						<!--                        <img :src="recommendliveobj_top.image" alt=""/>-->
						<!--                      </div>-->
						<!--                      <div class="right_day_box1_right">-->
						<!--                        <div class="right_day_box1_right_info">-->
						<!--                          <div class="right_day_box1_right_one">-->
						<!--                            {{ recommendliveobj_top.title }}-->
						<!--                          </div>-->
						<!--                          <div class="right_day_box1_right_two">-->
						<!--                            {{ recommendliveobj_top.category_name }}-->
						<!--                          </div>-->
						<!--                        </div>-->
						<!--                        <div class="right_day_box1_right_play">-->
						<!--                          <img src="@/assets/play2.png" alt=""/>-->
						<!--                        </div>-->
						<!--                      </div>-->
						<!--                    </div>-->
						<!--                  </div>-->
						<!--                  <div class="right_day_x"></div>-->
						<!--                  <div class="right_day_list" v-if="recommendliveobj_list.length > 0">-->
						<!--                    <div class="right_day_list_item" v-for="(item, index) in recommendliveobj_list.slice(0, 5)" :key="index"-->
						<!--                         @click="jump(item.id,item.is_member_expire,item)">-->
						<!--                      <div class="right_day_list_item_tps">-->
						<!--                        【日更】-->
						<!--                      </div>-->
						<!--                      <div class="right_day_list_item_wz">-->
						<!--                        {{ item.title }}-->
						<!--                      </div>-->
						<!--                      <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">-->
						<!--                    </div>-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--              </div>-->
						<!--              &lt;!&ndash; 问答社区 &ndash;&gt;-->
						<!--              <div class="right_day">-->
						<!--                    <div class="right_day_head">-->
						<!--                        <div class="right_day_head_left">-->
						<!--                            <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                                <img src="@/assets/jian@2x.png" alt=""/>-->
						<!--                                <span>{{ sysconfig.web_sidebar_as_2 }}</span>-->
						<!--                            </div>-->
						<!--                            <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                                <span>更多</span>-->
						<!--                                <img style="width: 30px;height: 30px;" src="@/assets/gengduo.png" alt=""/>-->
						<!--                            </div>-->
						<!--                        </div>-->
						<!--                    </div>-->
						<!--                    <div v-if="dailyliveobj_top && dailyliveobj_list.length > 0" style="cursor: pointer">-->
						<!--                        <div class="right_day_box2"-->
						<!--                             @click="jump(dailyliveobj_top.id,dailyliveobj_top.is_member_expire,dailyliveobj_top)">-->
						<!--                            <div class="right_day_box2_top">-->
						<!--                                <div class="right_day_box1_left">-->
						<!--                                    <img :src="dailyliveobj_top.image" alt=""/>-->
						<!--                                </div>-->
						<!--                                <div class="right_day_box1_right">-->
						<!--                                    <div class="right_day_box1_right_info">-->
						<!--                                        <div class="right_day_box1_right_one">-->
						<!--                                            {{ dailyliveobj_top.title }}-->
						<!--                                        </div>-->
						<!--                                        <div class="right_day_box1_right_two">-->
						<!--                                            {{ dailyliveobj_top.category_name }}-->
						<!--                                        </div>-->
						<!--                                    </div>-->
						<!--                                    <div class="right_day_box1_right_play">-->
						<!--                                        <img src="@/assets/play.png" alt=""/>-->
						<!--                                    </div>-->
						<!--                                </div>-->
						<!--                            </div>-->
						<!--                        </div>-->
						<!--                        <div class="right_day_x"></div>-->
						<!--                        <div class="right_day_list" v-if="dailyliveobj_list.length>0">-->
						<!--                            <div class="right_day_list_item" v-for="(item, index) in dailyliveobj_list.slice(0, 5)" :key="index"-->
						<!--                                 @click="jump(item.id,item.is_member_expire,item)">-->
						<!--                                <div class="right_day_list_item_tps">-->
						<!--                                    【日更】-->
						<!--                                </div>-->
						<!--                                <div class="right_day_list_item_wz">-->
						<!--                                    {{ item.title }}-->
						<!--                                </div>-->
						<!--                                <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">-->
						<!--                            </div>-->
						<!--                        </div>-->
						<!--                    </div>-->
						<!--                </div>-->

						<!-- 小道消息 -->
						<!--            <div class="right_day">-->
						<!--              <div class="right_day_head">-->
						<!--                <div class="right_day_head_left">-->
						<!--                    <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                        <img src="@/assets/jian@2x.png" alt=""/>-->
						<!--                        <span>{{ sysconfig.web_sidebar_as_5 }}</span>-->
						<!--                    </div>-->
						<!--                    <div style="display: flex;align-items: center;cursor: pointer;">-->
						<!--                        <span>更多</span>-->
						<!--                        <img style="width: 30px;height: 30px;" src="@/assets/gengduo.png" alt=""/>-->
						<!--                    </div>-->
						<!--                </div>-->
						<!--              </div>-->
						<!--              <div class="right_day_list">-->
						<!--                <div class="right_day_list_item" v-for="(item, index) in message.slice(0, 5)" :key="index"-->
						<!--                     @click="jumpInfo(item)">-->
						<!--                  <img class="right_day_list_item_icon" src="@/assets/wb.png" alt=""/>-->
						<!--                  <div class="right_day_list_item_wz">-->
						<!--                    {{ timestampToTime(item.createtime, 1) }} {{ item.title }}-->
						<!--                  </div>-->
						<!--                </div>-->
						<!--              </div>-->
						<!--            </div>-->
					</div>
				</div>

				<!-- 底部信息 -->
				<div class="layout_content_footer">
					<div class="layout_content_footer_top"></div>
					<div class="layout_content_footer_bottom">
						<span
							>Copyright 2012-2021 {{ sysconfig.web_name }} All Rights
							Reserved.</span
						>
						<span
							>版权声明：{{ sysconfig.web_name }}，未经授权，
							请勿转载或建立镜像，违者依法必究。</span
						>
						<span
							><a
								href="https://beian.mps.gov.cn/#/query/webSearch?code=12011002023051"
								rel="noreferrer"
								target="_blank"
								style="text-decoration: none; color: inherit"
								>{{ sysconfig.web_beian }}
							</a></span
						>
						<span style="margin-left: 20px"
							><a
								:href="sysconfig.web_icp_query_link"
								rel="noreferrer"
								target="_blank"
								style="text-decoration: none; color: inherit"
								>{{ sysconfig.web_icp }}
							</a></span
						>
					</div>
				</div>
			</div>
			<!-- 用户信息 -->
			<div style="position: relative">
				<userInfo ref="userContent" />
			</div>

			<!--      //聊天-->
			<div
				style="
					background-color: #072451;
					width: 55px;
					height: 37px;
					z-index: 9;
					padding: 6px;
					position: fixed;
					right: 0;
					bottom: 23%;
					border-top-left-radius: 50px;
					border-bottom-left-radius: 50px;
				"
				@click="Group_chat"
			>
				<img src="@/assets/chatnew.png" style="width: 50px;position: absolute;top: 0;left: -10px;">
				<div style=" writing-mode: vertical-rl;text-orientation: upright;white-space: nowrap;text-align:center;color:#f89a1e;font-size: 15px;margin-left:35px;margin-top:5px;">群聊</div>
				<!-- <img
					style="
						width: 40px;
						height: 35px;
						z-index: 10;
						transform: translate(10px, 2px);
					"
					src="@/assets/chat.png"
				/> -->
			</div>

			<!-- 返回顶部 -->
			<el-backtop :bottom="70" :right="10">
				<div
					style="
						height: 100%;
						width: 100%;
						background-color: var(--el-bg-color-overlay);
						box-shadow: var(--el-box-shadow-lighter);
						text-align: center;
						line-height: 40px;
						color: #1989fa;
					"
				>
					UP
				</div>
			</el-backtop>
			<div v-if="showPreview" class="modal" @click="closePreview">
				<div style="display: flex; flex-wrap: wrap">
					<div style="position: relative; width: 100%">
						<span class="close" @click="closePreview">&times;</span>
					</div>
					<div class="modal-content">
						<img
							:src="images1[currentImageIndex]"
							alt="Preview"
							style="max-width: 80%; max-height: 80%"
						/>
					</div>
				</div>
			</div>

			<div
				style="
					position: fixed;
					top: 35%;
					z-index: 990;
					width: 688px;
					background-color: #fff;
					border-radius: 10px;
					padding: 5px;
				"
				v-if="is_update"
			>
				<div
					class="item_pl_user"
					style="display: flex; justify-content: space-between"
				>
					<span> 修改内容 </span>
					<span style="cursor: pointer" @click.stop="closeupdate()">X</span>
				</div>
				<textareaComponent
					:typeView="typeView"
					:channel="update_chid"
					:con="update_info"
					:inputtype="8"
					@result="sendMessageResult"
					v-if="is_update"
				/>
			</div>

			<el-dialog v-model="dialogFormVisible" title="开通会员" width="650">
				<div
					style="height: 200px; display: flex; z-index: 10"
					v-if="paytype == 1"
				>
					<div
						style="
							width: 20%;
							height: 200px;
							border: 2px #0b3d6c solid;
							background-color: #d3dce6;
							margin-left: 10px;
						"
						v-for="(item, index) in vipinfo"
						:key="index"
					>
						<div
							style="
								font-size: 22px;
								font-weight: bold;
								text-align: center;
								padding-top: 10px;
								color: #000;
							"
						>
							{{ item.name }}
						</div>
						<div
							style="
								font-size: 18px;
								font-weight: bold;
								text-align: center;
								padding-top: 10px;
								color: #000;
							"
							v-if="pay_status === 1"
						>
							<text style="color: red; font-size: 22px">{{
								item.membership_price
							}}</text>
							￥
						</div>
						<div
							style="
								font-size: 18px;
								font-weight: bold;
								text-align: center;
								padding-top: 10px;
								color: #000;
							"
							v-else
						>
							<text style="color: red; font-size: 22px">{{
								item.membership_score
							}}</text>
						</div>
						<div style="text-align: center; padding-top: 15px">
							<el-button
								type="primary"
								v-if="pay_status === 1"
								@click="dredge(item.id)"
								>开通</el-button
							>
							<el-button
								type="primary"
								v-else
								@click="dredge1(item.id, item.goods_type, item.goods_id)"
								>积分开通
							</el-button>
						</div>
					</div>
				</div>
				<div style="height: 200px; text-align: center; display: flex" v-else>
					<img :src="payimg" style="width: 200px; height: 200px" alt="" />
				</div>

				<template #footer>
					<span class="dialog-footer" style="margin-right: 10px">
						<el-button @click="cancellation()">取消</el-button>
					</span>
				</template>
			</el-dialog>

			<el-drawer
				title="聊天"
				v-model="customer_dialog"
				direction="rtl"
				custom-class="demo-drawer"
				ref="drawer"
			>
				<div class="demo-drawer__content">
					<!--              <el-form :model="form">-->
					<!--                  <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 50px;">-->
					<!--                      <el-input v-model="form.search" placeholder="请输入搜索关键词" @change="search_name"></el-input>-->
					<!--                  </div>-->
					<!--                  <el-col>-->
					<!--                      <el-card shadow="hover" @click="showChatWindow(item.nickname,item.id)" v-for="(item, index) in friends" :key="index" style="margin-top: 10px;">-->
					<!--                          <div style="display: flex;justify-content: flex-start;align-items: flex-start;">-->
					<!--                              <el-image style="width: 50px; height: 50px" :src="item.avatar_obs" :fit="fit"></el-image>-->
					<!--                              <div style="font-size: 16px;padding: 6px;user-select: none;">-->
					<!--                                  {{ item.nickname }}-->
					<!--                              </div>-->
					<!--                          </div>-->
					<!--                      </el-card>-->
					<!--                  </el-col>-->
					<!--              </el-form>-->
					<iframe :src="imurl" style="width: 99%; height: 94.5vh"></iframe>
				</div>
			</el-drawer>

			<!--      <el-dialog v-model="chatWindowVisible" :title="chatWindowTitle" @close="closeChatWindow" style="width: 450px;height: 600px;position: fixed;bottom: 0;margin-bottom: 0;right: 0px;">-->
			<!--          <ChatWindow :addressee_id="addressee_id" :key="keys"/>-->
			<!--      </el-dialog>-->

			<el-dialog v-model="dialogFormVisible1" title="开通会员" width="500">
				<div>暂未开通会员,开通会员观看!</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogFormVisible1 = false">取消</el-button>
						<el-button type="primary" @click="confirm"> 确认 </el-button>
					</span>
				</template>
			</el-dialog>

			<el-dialog v-model="dialogFormVisible3" title="开通会员" width="500">
				<el-button @click="payment">付费</el-button>
				<el-button @click="exchange">积分兑换</el-button>
				<el-button @click="apply_for">审核申请</el-button>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogFormVisible3 = false">取消</el-button>
					</span>
				</template>
			</el-dialog>
		</div>
		<vue-easy-lightbox
			class="img_review"
			:imgs="images"
			:index="image_index"
			:visible="visible"
			@hide="handleClose"
			:minZoom="0.5"
			:zoomScale="zoomScale_index"
			:maxZoom="zoomScale_index"
			:on-index-change="index_change"
		></vue-easy-lightbox>
	</div>
</template>

<script setup>
import userInfo from "@/components/userInfo.vue";
import lineElement from "@/components/line.vue";
import stickView from "@/components/stick.vue";
import logView from "@/components/log.vue";
import VideoView from "@/components/video.vue";
import LiveBroadcast from "@/components/live_broadcast.vue";
import chartList from "@/components/chartList.vue";
import searchList from "@/components/searchList.vue";
import Calendar from "@/components/calendar.vue";
import textareaComponent from "@/components/textareaComponent.vue";
import ChatWindow from "@/components/ChatWindow.vue";
import axios from "axios";
import { openImagePreview } from "@/components/openImagePreview";
const {
	visible,
	images,
	handleShow,
	handleClose,
	image_index,
	zoomScale_index,
} = openImagePreview();
import {
	get_management,
	liveList,
	grapevine,
	getCategoryList,
	getTopList,
	mark,
	systemConfig,
	getUserDetails,
	goodsShow,
	createVip,
	payVip,
	Vipquery,
	scorePay,
	getUserList,
	livelist,
	homePlaza,
	getSidebar, logout,
} from "@/api/index.js";
import {
	getCurrentDate,
	timestampToTime,
	getsolarToLunar,
	getweekstr,
} from "@/utils/utils.js";
import {
	ref,
	computed,
	onMounted,
	nextTick,
	defineExpose,
	onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

let friends = ref([]);
let imurl = ref("");
let imurl_div = ref("");
let addressee_id = ref(0);
let keys = ref(0);
let loading = ref(false);
let chatWindowVisible = ref(false);
let chatWindowTitle = ref("");
let customer_dialog = ref(false);
let customer_dialog_div = ref(false);
let fit = ref("cover");
let url = ref(
	"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
);
let form = ref({
	name: "",
	region: "",
	date1: "",
	date2: "",
	delivery: false,
	type: [],
	resource: "",
	desc: "",
});

//预览图片索引值改变时触发
const index_change = (oldIndex, newIndex) => {
	image_index.value = newIndex;
	const imgs = images.value[newIndex];
	const img = new Image();
	img.src = imgs;
	img.onload = () => {
		const bl =
			img.width < img.height
				? Math.ceil(img.height / img.width)
				: Math.ceil(img.width / img.height);
		if (bl < 5) {
			zoomScale_index.value = 2;
		} else {
			zoomScale_index.value = bl;
		}
	};
};

const showChatWindow = async (title, id) => {
	chatWindowTitle.value = title;
	chatWindowVisible.value = true;
	addressee_id.value = id;
	keys.value += 1;
};
const search_name = (e) => {
	getUserList({
		page: 1,
		limit: 40,
		keyword: e,
	}).then((res) => {
		if (res.code === 1) {
			friends.value = res.data.list;
		}
	});
};
const closeChatWindow = () => {
	chatWindowVisible.value = false;
};
const Group_chat = () => {
	customer_dialog.value = true;
	imurl.value =
		"https://im.fupanai.com/user/list?type=group&user_id=" + Invitation_id.value;
};

const Group_chat_div = () => {
	customer_dialog_div.value = true;
	console.log(sysconfig.value.top_im_user_chat_groups_id,1111)
	imurl_div.value = `https://im.fupanai.com/chat/groupIndex?user_id=${Invitation_id.value}&group_id=${sysconfig.value.top_im_user_chat_groups_id}`;
};

//托拽文件上传遮罩层
const upMask = (e) => {
	if (e === 1) {
		loading.value = true;
	} else {
		loading.value = false;
	}
};

const gossip_list = (item) => {
	if (item.parent_id === 8) {
		const routeUrl = router.resolve({
			path: "/home/gossipDetails",
		});
		window.open(routeUrl.href, "_blank");
	} else {
		clickItem(item.id, item.parent.name + " - " + item.name, item.parent_id);
	}
};

let dialogFormVisible = ref(false);
let dialogFormVisible1 = ref(false);
let dialogFormVisible3 = ref(false);
let pay_status = ref(0);
let searchListKey = ref(0);
let pay_alid = ref(0);
let vipinfo = ref([]);
let payimg = ref("");
let paytype = ref(1);

const store = useStore();
const router = useRouter();
const LineElement1 = ref(null);
const LineElement2 = ref(null);
const LineElement3 = ref(null);
const userContent = ref(null);
const stickRef = ref(null); // 论坛列表ref
const videoRef = ref(null); // 视频列表ref
const broadcastRef = ref(null); // 视频列表ref
const quantization = ref(null); // 量化信号ref
const searchinfo = ref(null); // 搜索列表
const logRef = ref(null); // 投研日志
const threeName = ref([]);
const getSidebar_list = ref([]);
let youhuancun = ref(0);
let caidanid1 = ref(0);
let caidanname1 = ref("");
let beforeUnloadTime = ref(0);
let caidanmenuId1 = ref(0);
let xian1 = ref(0);
let xian2 = ref(0);
let xian3 = ref(0);
let title = ref(""); // 模块标题
let title1 = ref(""); // 模块小标题
let Invitation = ref(""); // 邀请码加密字符串
let Invitation_id = ref(0); // 用户id
let menu = ref([]);
let bannerList = ref([]);
let childMenuId = ref(""); // 子级栏目id
let typeView = ref(""); // 判断显示内容区域组件
let selectedDate = null;
let time = timestampToTime(null, 2);
let liveobj = ref({}); // 视频数据
let dailyliveobj_top = ref([]);
let dailyliveobj_list = ref([]);
let inputtype = ref(2);
let weeklyliveobj_top = ref({});
let weeklyliveobj_list = ref({});
let recommendliveobj_top = ref({});
let recommendliveobj_list = ref({});
let keyword = ref(""); // 列表搜索关键字
let keyword_con = ref(""); // 列表搜索关键字
let timeVla = ref(Date.now() + 1000 * 60 * 10); // 列表刷新时间
let activeList = ref([]); // 激活日期
let dateYMD = ref(""); // 当前选中日期
let message = ref([]); // 小道消息数据
let sysconfig = ref({});
let showPreview = ref(false);
let images1 = ref([]);
let currentImageIndex = ref(0);
let is_update = ref(false);
const user = computed(() => store.state.userInfo); // 用户信息
let update_chid = ref(0);
let update_info = ref([]);
const shortcuts = [
	// 日期选择时间段
	{
		text: "近七天",
		value: () => {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
			return [start, end];
		},
	},
	{
		text: "近一月",
		value: () => {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			return [start, end];
		},
	},
	{
		text: "近一年",
		value: () => {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 360);
			return [start, end];
		},
	},
];

const cancellation = () => {
	dialogFormVisible.value = false;
	paytype.value = 1;
};
const dredge = (id) => {
	createVip({
		goods_type: "articles",
		table_name: "video",
		goods_id: id,
	}).then((res) => {
		if (res.code === 1) {
			payVip({ order_id: res.data.order_id }).then((res1) => {
				if (res1.code === 1) {
					payimg.value = res1.data.code_url;
					paytype.value = 2;
					const intervalId = setInterval(() => {
						Vipquery({ order_id: res.data.order_id }).then((res2) => {
							if (res2.code === 1) {
								if (res2.data.pay_status === 1) {
									clearInterval(intervalId); // 停止轮询
									location.reload();
								}
							}
						});
					}, 2000);
				}
			});
		}
	});
};
const dredge1 = (id, goods_type, goods_id) => {
	if (goods_type === "categories") {
		createVip({
			goods_type: goods_type,
			table_name: "video_category",
			goods_id: goods_id,
			pay_type: 2,
		}).then((res) => {
			if (res.code === 1) {
				dialogFormVisible3.value = false;
				dialogFormVisible.value = false;
				scorePay({ order_id: res.data.order_id }).then((res1) => {
					if (res1.code === 1) {
						ElMessage({ message: res1.msg, type: "success" });
					}
				});
			}
		});
	} else {
		createVip({
			goods_type: "articles",
			table_name: "video",
			goods_id: pay_alid.value,
			pay_type: 2,
		}).then((res) => {
			if (res.code === 1) {
				dialogFormVisible3.value = false;
				dialogFormVisible.value = false;
				scorePay({ order_id: res.data.order_id }).then((res1) => {
					if (res1.code === 1) {
						ElMessage({ message: res1.msg, type: "success" });
					}
				});
			}
		});
	}
};
const confirm = (val) => {
	if (pay_status.value === 1) {
		goodsShow({
			goods_type: "articles",
			table_name: "video",
			goods_id: pay_alid.value,
		}).then((res) => {
			if (res.code === 1) {
				vipinfo.value = res.data;
			}
		});
	}
	dialogFormVisible.value = true;
	dialogFormVisible1.value = false;
};
const payment = (val) => {
	pay_status.value = 1;
	dialogFormVisible3.value = false;
	dialogFormVisible1.value = true;
};
const exchange = (val) => {
	pay_status.value = 2;
	dialogFormVisible3.value = false;
	dialogFormVisible1.value = true;
	goodsShow({
		goods_type: "articles",
		table_name: "video",
		goods_id: pay_alid.value,
	}).then((res) => {
		if (res.code === 1) {
			vipinfo.value = res.data;
		}
	});
};
const apply_for = () => {
	createVip({
		goods_type: "articles",
		table_name: "video",
		goods_id: pay_alid.value,
		pay_type: 3,
	}).then((res) => {
		if (res.code === 1) {
			ElMessage({ message: "提交成功,等待审核", type: "success" });
			dialogFormVisible3.value = false;
		}
	});
};
const formatDate = (dateStr) => {
	const parts = dateStr.split(".");
	const year = parts[0];
	const month = String(parts[1]).padStart(2, "0");
	const day = String(parts[2]).padStart(2, "0");
	return `${year}-${month}-${day}`;
};
// 日历关键字搜索
const keywordFc = () => {
	dateYMD.value = formatDate(getCurrentDate());
	keyword_con.value = keyword.value;
	typeView.value = 9;
	searchListKey.value += 1;
};
const closeupdate = (item) => {
	is_update.value = false;
};
// 跳转小道消息
const jumpInfo = (item) => {
	// const routeUrl = router.resolve({
	//   path: "/home/forumDetails",
	//   query: {id: id, type: '', status: '2'}
	// });
	window.open(item.form_url, "_blank");
};

// 顶部轮播图跳转
const jumpBanner = (item) => {
	if (item.type === "1") {
	} else if (item.type === "2") {
		if (item.channels_id_2 !== "") {
			clickItem(
				item.channels_id_2,
				item.channels_name_1 + " - " + item.channels_name_2,
				item.channels_id_1
			);
		} else {
			clickItem1(item.channels_id_2, item.channels_name_1, item.channels_id_2);
		}
	} else if (item.type === "3") {
		window.open(item.content);
	}
};

// 栏目列表---树结构
const getCategoryLists = () => {
	getCategoryList().then((res) => {
		if (res.code === 1) {
			const array = []
      for (let i = 0; i < res.data.length; i++) {
        console.log(res.data[i]); // 打印每个元素或处理每个元素
        if(res.data[i].name == '债市广场'){
          array.push(res.data[i])
        }
      }
      menu.value = array;
			console.log(menu.value,'菜单')
			typeView.value = res.data[0].id;
			if (res.data[0].children.length == 0) {
				title.value = res.data[0].name;
				childMenuId.value = res.data[0].id;
			} else if (res.data[0].children[0].children.length == 0) {
				title.value = `${res.data[0].name} - ${res.data[0].children[0].name}`;
				childMenuId.value = res.data[0].children[0].id;
			} else {
				title.value = `${res.data[0].name} - ${res.data[0].children[0].name} - ${res.data[0].children[0].children[0].name}`;
				childMenuId.value = res.data[0].children[0].children[0].id;
			}
			if (youhuancun.value == 0) {
				nextTick(() => {
					getMark();
					if (typeView.value == 4) {
						// getTimeTips()
						const parts = title.value.split(" - ");
						title.value = parts[0];
						title1.value = parts[1];
						videoRef.value.getVideoList("rem");
					} else if (typeView.value == 1) {
						// getNewsTimeTips()
						const parts = title.value.split(" - ");
						title.value = parts[0];
						title1.value = parts[1];
						stickRef.value.getNewList4("rem");
					} else if (typeView.value == 3 || typeView.value == 5) {
						// getNewsTimeTips()
						const parts = title.value.split(" - ");
						title.value = parts[0];
						title1.value = parts[1];
						stickRef.value.getNewList2("rem");
					} else if (typeView.value == 2) {
						const parts = title.value.split(" - ");
						title.value = parts[0];
						title1.value = parts[1];
						quantization.value.getData();
					}
				});
			} else {
				if (
					caidanmenuId1.value == 4 ||
					caidanmenuId1.value == 1 ||
					caidanmenuId1.value == 3 ||
					caidanmenuId1.value == 5 ||
					caidanmenuId1.value == 2 ||
					caidanmenuId1.value == 7 ||
					caidanmenuId1.value == 6
				) {
					clickItem1(caidanid1.value, caidanname1.value, caidanmenuId1.value);
				} else {
					const parts = title.value.split(" - ");
					title.value = parts[0];
					title1.value = parts[1];
					clickItem(caidanid1.value, caidanname1.value, caidanmenuId1.value);
				}
			}
		}
	});
};
const updateTitleAndFavicon1 = (text) => {
	document.title = text;
};
// 点击栏目
const clickItem = (id, name, menuId) => {


  console.log(id, name, menuId,222)

	localStorage.setItem("caidanid", id);
	localStorage.setItem("caidanname", name);
	localStorage.setItem("caidanmenuId", menuId);
	childMenuId.value = id;
	title.value = name;
	typeView.value = menuId;
	inputtype.value = id;
	nextTick(() => {
		// 清空日期标记
		getMark();
		activeList.value = [];
		updateTitleAndFavicon1("复盘 - " + name);
		if (typeView.value == 4) {
			const parts = title.value.split(" - ");
			title.value = parts[0];
			title1.value = parts[1];
			// updateTitleAndFavicon1('复盘 - ' + name)
			if (id != 4) {
				videoRef.value.getVideoList("rem");
			}
		} else if (
			typeView.value == 1 ||
			typeView.value == 3 ||
			typeView.value == 5
		) {
			if (typeView.value == 3) {
				// updateTitleAndFavicon1(name.substring(7))
			}
			const parts = title.value.split(" - ");
			title.value = parts[0];
			title1.value = parts[1];
			stickRef.value.getNewList("rem");
		} else if (typeView.value == 2) {
			const parts = title.value.split(" - ");
			title.value = parts[0];
			title1.value = parts[1];
			// updateTitleAndFavicon1(name.substring(7))
			if (id != 2) {
				quantization.value.getData();
			}
		} else if (typeView.value == 7) {
			const parts = title.value.split(" - ");
			title.value = parts[0];
			title1.value = parts[1];
			// updateTitleAndFavicon1(name.substring(6))
			if (id != 7) {
				logRef.value.getDate("rem");
			}
		} else if (typeView.value == 6) {
			const parts = title.value.split(" - ");
			title.value = parts[0];
			title1.value = parts[1];
			// updateTitleAndFavicon1(name.substring(7))
			if (id != 6) {
				broadcastRef.value.getVideoList("rem");
			}
		}
	});
};
const clickItem1 = (id, name, menuId) => {
	localStorage.setItem("caidanid", id);
	localStorage.setItem("caidanname", name);
	localStorage.setItem("caidanmenuId", menuId);
	childMenuId.value = id;
	title.value = name;
	typeView.value = menuId;
	nextTick(() => {
		// 清空日期标记
		activeList.value = [];
		getMark();
		if (typeView.value == 4) {
			updateTitleAndFavicon1("复盘 - 成长计划");
			videoRef.value.getVideoList1("rem");
		} else if (typeView.value == 1) {
			stickRef.value.getNewList4("rem");
			updateTitleAndFavicon1("复盘 - 首页广场");
			// stickRef.value.getNewList2('rem')
		} else if (typeView.value == 3) {
			updateTitleAndFavicon1("复盘 - 债市广场");
			stickRef.value.getNewList2("rem");
		} else if (typeView.value == 5) {
			stickRef.value.getNewList1("rem");
		} else if (typeView.value == 2) {
			updateTitleAndFavicon1("复盘 - 量化辅助");
			quantization.value.getData1("rem");
		} else if (typeView.value == 7) {
			updateTitleAndFavicon1("复盘 - 数据库");
			logRef.value.getDate1("rem");
		} else if (typeView.value == 6) {
			updateTitleAndFavicon1("复盘 - 直播专区");
			broadcastRef.value.getVideoList1("rem");
		}
	});
};
// 小道消息
const getgrapevine = () => {
	grapevine().then((res) => {
		if (res.code === 1) {
			message.value = res.data.list;
			if (res.data.list) {
			}
		}
	});
};

// 发布文章回调
const sendMessageResult = (event) => {
	if (event) {
		if (childMenuId.value == 9) {
			keywordFc();
		} else {
			if (childMenuId.value == 1) {
				stickRef.value.getNewList4("rem");
			} else if (childMenuId.value == 3) {
				stickRef.value.getNewList2("rem");
			} else {
				stickRef.value.getNewList("rem");
			}
		}
		// stickRef.value.getNewList2('rem')
		is_update.value = false;
	}
};
// 重置倒计时时间
const reset = () => {
	timeVla.value = Date.now() + 1000 * 60 * 10;
};
// 打开用户信息弹窗
const openUser = () => {
	userContent.value.open();
};
// 标记日期
const getMark = () => {
	let category_type = "";
	if (typeView.value == 1) {
		category_type = "plaza";
	} else if (typeView.value == 3) {
		category_type = "plate";
	} else if (typeView.value == 5) {
		category_type = "news";
	} else if (typeView.value == 4) {
		category_type = "video";
	}
	category_type = "plate";
	mark({
		year_month: time,
		category_type,
		category_id: childMenuId.value,
	}).then((res) => {
		if (res.code === 1) {
			activeList.value = [];
			for (let key in res.data.days) {
				if (res.data.days[key] > 0) {
					activeList.value.push(key);
				}
			}
		}
	});
};
// 切换年月
const changeDate = (val) => {
	time = timestampToTime(val, 2);
	getMark();
	// if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
	//     getNewsTimeTips()
	// } else if (typeView.value == 4) {
	//     getTimeTips()
	// }
};
// 切换日期
const onSelectedDateUpdated = (selectedDateVal) => {
	dateYMD.value = timestampToTime(selectedDateVal, 1);
	selectedDate = selectedDateVal;
	time = timestampToTime(selectedDateVal, 2);
	typeView.value = 9;
	searchListKey.value += 1;
	// nextTick(() => {
	//   if (typeView.value == 4) {
	//     videoRef.value.getVideoList('rem')
	//   } else if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
	//     stickRef.value.getNewList('rem')
	//   } else if (typeView.value == 2) {
	//     quantization.value.getData()
	//   }
	// })
};
// 获取轮播图列表
const getBanner = () => {
	get_management().then((res) => {
		if (res.code === 1) {
			bannerList.value = res.data;
		}
	});
};

// 获取视频
const getliveList = () => {
	liveList({ page: 1, type: 1, limit: 10 }).then((res) => {
		if(res.code === 1){
			dailyliveobj_top.value = res.data.top_data;
			dailyliveobj_list.value = res.data.list_data;
		}
	});
	liveList({ page: 1, type: 2, limit: 10 }).then((res) => {
		if(res.code === 1){
			weeklyliveobj_top.value = res.data.top_data;
			weeklyliveobj_list.value = res.data.list_data;
		}
	});
	liveList({ page: 1, type: 3, limit: 10 }).then((res) => {
		if(res.code === 1){
			recommendliveobj_top.value = res.data.top_data;
			recommendliveobj_list.value = res.data.list_data;
		}
	});
};

// 跳转视频详情
// const jump = (id, is_member_expire, item) => {
//   if (is_member_expire === 1) {
//     pay_alid.value = id
//     dialogFormVisible3.value = true
//     return
//   }
//   const routeUrl = router.resolve({
//     path: "/home/videoDetails",
//     // query: {id}
//     query: {id: id, type: item.category_type, comment_type: item.comment_type, status: '1'}
//   });
//   window.open(routeUrl.href, "_blank");
// }

const jump = (item) => {
	if (item.type === "article") {
		const routeUrl = router.resolve({
			path: "/home/forumDetails",
			// query: {id: item.id, comment_type:item.comment_type}
			query: { id: item.id, type: "plate", comment_type: "bbs", status: "1" },
		});
		window.open(routeUrl.href, "_blank");
	} else if (item.type === "gossip") {
		window.open(item.form_url, "_blank");
	} else {
		const routeUrl = router.resolve({
			path: "/home/videoDetails",
			// query: {id: item.id, comment_type:item.comment_type}
			query: { id: item.id, type: "video", comment_type: "bbs", status: "1" },
		});
		window.open(routeUrl.href, "_blank");
	}
};

// 跳转直播详情
const jumpLive = (obj) => {
	const routeUrl = router.resolve({
		path: "/home/liveDetails",
		query: { obj: JSON.stringify(obj) },
	});
	window.open(routeUrl.href, "_blank");
};
// 获取顶部三个图表数据
// const getTopLists = () => {
//   getTopList({}) .then(res => {
//     if (res.code === 1) {
//       threeName.value = res.data
//         xian1.value = res.data[0].is_member_expire
//         xian2.value = res.data[1].is_member_expire
//         xian3.value = res.data[2].is_member_expire
//       if (res.data.length > 0) {
//         if (res.data[0]) {
//           LineElement1.value.setLine(res.data[0].data)
//         }
//         if (res.data[1]) {
//           LineElement2.value.setLine(res.data[1].data)
//         }
//         if (res.data[2]) {
//           LineElement3.value.setLine(res.data[2].data)
//         }
//       }
//     }
//   })
// }
const getSystemConfig = () => {
	systemConfig().then((res) => {
		sysconfig.value = res.data;
    obtain_user();
	});
};

const getSidebarList = () => {
	getSidebar().then((res) => {
		getSidebar_list.value = res.data;
	});
};

const goshouyeguangchang = () => {
	typeView.value = 3;
	setTimeout(() => {
		childMenuId.value = 3;
		// title.value = '首页广场'
		title.value = "债市广场";
		updateTitleAndFavicon1("复盘 - " + title.value);
		getMark();
		stickRef.value.getNewList2("rem");
		// stickRef.value.getNewList('rem')
	}, 100);
};

const openPreview = (e) => {
	images1.value = e.ima;
	showPreview.value = true;
	currentImageIndex.value = e.index;
};

const closePreview = (index) => {
	showPreview.value = false;
};

const updateContent = (e) => {
	update_chid.value = e.channel_id;
	update_info.value = e;
	is_update.value = true;
};

const shareQQ = (key) => {
	var url = window.location.href;
	const newUrl = url.replace("home", "");
	let urls = "";
	if (key === "暂无") {
		urls = newUrl;
	} else {
		urls = newUrl + "?key=" + key;
	}
	var encodedUrl = encodeURIComponent(urls);
	var shareUrl =
		"http://connect.qq.com/widget/shareqq/index.html?url=" + encodedUrl;
	window.open(shareUrl);
};

// 分享到微博
const shareMicroBlog = (key) => {
	let message = String(location.href);
	const newUrl = message.replace("home", "");
	let urls = "";
	if (key === "暂无") {
		urls = newUrl;
	} else {
		urls = newUrl + "?key=" + key;
	}
	var url =
		"https://service.weibo.com/share/share.php?url=" +
		encodeURIComponent(urls) +
		"&appkey=MrShi&title=测试标题&language=zh_cn";
	window.open(url);
	// count=表示是否显示当前页面被分享数量(1显示)(可选，允许为空)
	// &url=将页面地址转成短域名，并显示在内容文字后面。(可选，允许为空)
	// &appkey=用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
	// &title=分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
	// &pic=自定义图片地址，作为微博配图(可选，允许为空)
	// &ralateUid=转发时会@相关的微博账号(可选，允许为空)
	// &language=语言设置(zh_cn|zh_tw)(可选)
};

//分享到微信
const shareWeChat = (key) => {
	var url = window.location.href;
	const newUrl = url.replace("home", "");
	let urls = "";
	if (key === "暂无") {
		urls = newUrl;
	} else {
		urls = newUrl + "?key=" + key;
	}
	var encodedUrl = encodeURIComponent(urls);
	var qrcodeUrl = "https://login.weixin.qq.com?url=" + encodedUrl;
	window.open(qrcodeUrl);
};

const obtain_user = () => {
	getUserDetails().then((res) => {
		if(res.code == 1){
			Invitation.value = res.data.invitation_code_key;
			Invitation_id.value = res.data.id;
			Group_chat_div();
		}
	});
};
const livelists = () => {
	livelist().then((res) => {
		if(res.data.code === 1){
			liveobj.value = res.data;
		}
	});
};

const updateTitleAndFavicon = () => {
	document.title = "复盘";
	const favicon = document.querySelector('link[rel="icon"]');
	if (favicon) {
		favicon.href =
			"https://video-source.obs.cn-north-4.myhuaweicloud.com/img/default_user.jpg";
	}
};

const clearCache = () => {
	localStorage.removeItem("caidanid");
	localStorage.removeItem("caidanname");
	localStorage.removeItem("caidanmenuId");
	youhuancun.value = 0;
};

const retrieveFromLocalStorage = () => {
	const caidanid = localStorage.getItem("caidanid");
	const caidanname = localStorage.getItem("caidanname");
	const caidanmenuId = localStorage.getItem("caidanmenuId");
	if (caidanid !== null && caidanname !== null && caidanmenuId !== null) {
		youhuancun.value = 1;
		caidanid1.value = caidanid;
		caidanname1.value = caidanname;
		caidanmenuId1.value = caidanmenuId;
		const parts = title.value.split(" - ");
		title.value = parts[0];
		title1.value = parts[1];
		clickItem(caidanid, caidanname, caidanmenuId);
	}
};

const beforeunloadHandler = () => {
	beforeUnloadTime.value = new Date().getTime();
};

const unloadHandler = (e) => {
	let unloadTime = new Date().getTime() - beforeUnloadTime.value;
	if (unloadTime <= 8) {
		clearCache();
	}
};

onUnmounted(() => {
	window.removeEventListener("beforeunload", (e) => beforeunloadHandler(e));
	window.removeEventListener("unload", (e) => unloadHandler(e));
});

onMounted(() => {
  if(localStorage.getItem('is_required_userinfo') == 0){
    logout()
        .then(res => {
          store.commit('empty')
          router.push('/')
          document.querySelector('body').classList.remove('no-scroll');
        })
  }
	window.addEventListener("beforeunload", (e) => beforeunloadHandler(e));
	window.addEventListener("unload", (e) => unloadHandler(e));
  getSystemConfig();
	retrieveFromLocalStorage();
	updateTitleAndFavicon();
	getBanner();
	getliveList();
	// getTopLists()
	getgrapevine();
	getCategoryLists();
	livelists();
	getSidebarList();
});
</script>

<style>
.el-dialog__header {
	background-color: #0055aa !important;
	margin-right: 0 !important;
}

.el-dialog__title {
	color: #fff !important;
}

.el-drawer__header {
	background-color: #0055aa !important;
	color: #fff !important;
	margin: 0 !important;
	height: 0px !important;
	padding-bottom: 20px !important;
}

.el-drawer__body {
	padding: 0 !important;
}

.resize-image {
	width: 100%;
	height: 70px;
	object-fit: contain;
	display: block;
}

.infinite-list {
	height: 300px;
	padding: 0;
	margin: 0;
	list-style: none;
}

.infinite-list .infinite-list-item {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	background: var(--el-color-primary-light-9);
	margin: 10px;
	color: var(--el-color-primary);
}

.infinite-list .infinite-list-item + .list-item {
	margin-top: 10px;
}

.el-loading-spinner {
	position: fixed !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: #28bed7;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}

.layout {
	width: 100%;
	min-width: 975px;
	background-color: #0d2e64;
	display: flex;
	justify-content: center;
	position: relative;

	.layout_bg {
		width: 100%;
		height: 315px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background-image: url("@/assets/bg2.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.layout_content {
		width: 1200px;
		height: auto;
		color: azure;

		.layout_content_head {
			width: 100%;
			height: 83px;
			background: rgba(0, 18, 48, 0.5);
			border-radius: 26px;
			margin-top: 25px;
			box-sizing: border-box;
			position: relative;
			z-index: 2;
			padding: 0 8px;

			.layout_content_head_day {
				font-size: 28px;
				//font-family: '汉仪菱心体简';
				font-weight: 400;
				color: #ffffff;
				//display: flex;
				//flex-wrap: wrap;
				margin-left: 15px;
			}

			.layout_content_head_banner::v-deep {
				flex-shrink: 0;
				//width: 526px;
				width: 65%;
				height: 70px;
				margin: 0 6px;
				cursor: pointer;

				.el-carousel {
					width: 100%;

					//.el-carousel__item:nth-child(2n) {
					//  background-color: #99a9bf;
					//}
					//
					//.el-carousel__item:nth-child(2n+1) {
					//  background-color: #d3dce6;
					//}

					.el-carousel__indicators {
						left: unset;
						transform: unset;
						right: 50%;
						transform: translate(50%, 20%);
					}

					.el-carousel__item {
						position: absolute;
						top: 0;
						left: -30px;
					}

					.el-carousel__button {
						width: 8px;
						height: 8px;
						border: none;
						border-radius: 50%;
						background-color: #ffffff;
					}

					.is-active .el-carousel__button {
						background: #3f8ec8;
					}
				}
			}

			.layout_content_head_user {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				cursor: pointer;
				margin-right: 20px;

				.user_img {
					width: 53px;
					height: 53px;
					border-radius: 50%;
					overflow: hidden;
					margin-right: 16px;

					img {
						width: 100%;
					}
				}

				.user_info {
					display: flex;
					flex-direction: column;

					span {
						font-size: 15px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #ffffff;
					}

					.user_info_ststus {
						display: flex;
						align-items: center;
						//margin-top: 3px;

						img {
							width: 12px;
							height: 12px;
							margin-right: 5px;
						}

						.user_info_ststus_border {
							// width: 39px;
							// height: 15px;
							padding: 0 2px;
							border: 2px solid #f6cc05;
							border-radius: 3px;
							display: flex;
							align-items: center;
							justify-content: center;

							span {
								font-size: 7px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #ffd200;
							}
						}

						.fenxiang-box {
							background: #ffd200;
							border-radius: 2px;
							text-align: center;
							margin: 5px;
							font-size: 11px;
							padding: 0 9px;
						}
					}
				}
			}
		}

		.layout_content_picture {
			width: 100%;
			display: flex;
			align-items: center;
			margin-top: 19px;
			position: relative;
			z-index: 2;

			.layout_content_picture_item {
				flex: 1;
				height: 271px;
				margin-right: 19px;
				background: rgba(2, 24, 63, 0.5);
				border-radius: 16px;
				padding: 16px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;

				&:last-child {
					margin-right: 0;
				}

				.layout_content_picture_item_head::v-deep {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.el-range-editor--small.el-input__wrapper {
						width: 160px;

						.el-range__icon {
							display: none;
						}
					}

					.layout_content_picture_item_head_title {
						display: flex;
						align-items: center;

						img {
							width: 16px;
							height: 10px;
							margin-right: 8px;
						}

						span {
							font-size: 13px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #00d5d8;
						}
					}

					.layout_content_picture_item_head_sele {
						// width: 72px;
						padding: 0 5px;
						box-sizing: border-box;
						height: 23px;
						background: rgba(13, 46, 100, 0.6);
						border-radius: 3px;
						padding: 0 11px;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						justify-content: space-between;
						position: relative;
						cursor: pointer;
						// &:hover {
						//     .sele_menu {
						//         height: 150px;
						//     }
						// }
						// .sele_menu::-webkit-scrollbar {
						//     width: 5px;
						// }
						// .sele_menu::-webkit-scrollbar-thumb {
						//     border-radius: 8px;
						//     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
						//     background: #5D6B82;
						// }
						.sele_menu {
							position: absolute;
							top: 100%;
							left: 0;
							width: 100%;
							height: 0;
							overflow-y: scroll;
							border-radius: 5px;
							transition: 0.5s;
							z-index: 99;
							background-color: rgba(2, 24, 63, 0.5);

							.sele_menu_item {
								width: 100%;
								height: 30px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 13px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #ffffff;

								&:hover {
									background-color: rgba(2, 24, 63, 0.5);
								}
							}
						}

						span {
							font-size: 13px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #ffffff;
							margin-right: 3px;
						}

						img {
							width: 13px;
							height: 7px;
						}
					}
				}

				.layout_content_picture_item_tu {
					width: 100%;
					height: calc(100% - 20px);
				}
			}
		}

		.layout_content_box {
			width: 100%;
			margin-top: 16px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.layout_content_box_menu {
				// width: 88px;
				width: 110px;
				height: 100vh;
				position: sticky;
				z-index: 3;
				top: 0;
				left: 0;
				border-radius: 16px 16px 0px 0px;
				background-color: rgba(2, 24, 63, 0.5);
				display: flex;
				flex-direction: column;

				.menu_logo {
					width: 100%;
					height: 73px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 6px solid #0d2e64;

					img {
						width: 54px;
						height: 33px;
					}
				}

				.menu_top {
					width: 100%;
					height: calc(100% - 73px);
					// 294px
					display: flex;
					flex-direction: column;
					padding-bottom: 10px;
					box-sizing: border-box;
					border-bottom: 6px solid #0d2e64;

					.active {
						border-left: 3px solid #00d5d8 !important;
						background: rgba(0, 213, 216, 0.2) !important;

						span {
							color: #00d5d8 !important;
						}
					}

					.menu_top_item {
						width: 100%;
						height: 45px;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						cursor: pointer;
						z-index: 9;

						&:hover {
							.menu_top_item_list {
								display: block;
							}

							.menu_top_item_text {
								font-weight: bold !important;
							}
						}

						.menu_top_item_text {
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							text-align: center;
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #ffffff;
						}

						.menu_top_item_list {
							display: block;
							width: 110px;
							max-height: 270px;
							//height: 270px;
							overflow-y: auto;
							position: absolute;
							left: 0;
							top: 50px;
							z-index: 999;
							background-color: rgba(2, 24, 63, 0.5);

							.menu_top_item_list_item {
								width: 100%;
								height: 45px;
								display: flex;
								align-items: center;
								justify-content: center;
								position: relative;
								z-index: 999;
								cursor: pointer;

								&:hover {
									.menu_top_item_list_list {
										display: block;
									}

									.menu_top_item_list_item_text {
										font-weight: bold !important;
									}
								}

								.menu_top_item_list_item_text {
									width: 100%;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									text-align: center;
									font-size: 14px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #ffffff;
								}

								.menu_top_item_list_list {
									display: none;
									width: 110px;
									position: absolute;
									right: -100%;
									top: 0;
									z-index: 999;
									background-color: rgba(2, 24, 63, 0.5);

									.menu_top_item_list_list_item {
										width: 100%;
										height: 45px;
										display: flex;
										align-items: center;
										justify-content: center;
										position: relative;
										z-index: 999999;
										cursor: pointer;

										&:hover {
											.menu_top_item_list_list_item_text {
												font-weight: bold !important;
											}
										}

										.menu_top_item_list_list_item_text {
											width: 100%;
											overflow: hidden;
											white-space: nowrap;
											text-overflow: ellipsis;
											text-align: center;
											font-size: 14px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											color: #ffffff;
										}
									}
								}
							}
						}
					}
				}

				.menu_bottom::-webkit-scrollbar {
					width: 5px;
				}

				.menu_bottom::-webkit-scrollbar-thumb {
					border-radius: 8px;
					box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
					background: #5d6b82;
				}

				// .menu_bottom {
				//     width: 100%;
				//     height: calc(100% - 294px);
				//     overflow-y: scroll;
				//     padding-bottom: 10px;
				//     box-sizing: border-box;
				//     .active {
				//         border-left: 3px solid #00D5D8 !important;
				//         background: rgba(0, 213, 216, 0.2) !important;
				//         span {
				//             color: #00D5D8 !important;
				//         }
				//     }
				//     .menu_top_item {
				//         width: 100%;
				//         height: 39px;
				//         display: flex;
				//         align-items: center;
				//         justify-content: center;
				//         box-sizing: border-box;
				//         margin-top: 10px;
				//         cursor: pointer;
				//         &:hover {
				//             span {
				//                 font-weight: bold !important;
				//             }
				//         }
				//         span {
				//             font-size: 14px;
				//             font-family: Source Han Sans CN;
				//             font-weight: 400;
				//             color: #FFFFFF;
				//         }
				//     }
				// }
			}

			.layout_content_box_nr {
				// flex: 1;
				width: 60%;
				height: auto;
				margin: 0 18px;
				position: relative;
				z-index: 2;
			}

			.layout_content_box_right {
				width: 313px;
				height: auto;
				display: flex;
				flex-direction: column;

				.right_day {
					width: 100%;
					height: auto;
					padding: 9px 17px;
					box-sizing: border-box;
					border-radius: 16px;
					background-color: rgba(2, 24, 63, 0.5);
					margin-bottom: 14px;

					&:last-child {
						margin-bottom: 0;
					}

					.right_day_head {
						width: 100%;
						height: 23px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.right_day_head_left {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;

							img {
								width: 16px;
								height: 10px;
								margin-right: 8px;
							}

							span {
								line-height: 23px;
								font-size: 13px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #00d5d8;
							}
						}
					}

					.right_day_box {
						width: 100%;
						margin-top: 15px;
					}

					.right_day_box1 {
						width: 100%;
						margin-top: 15px;
						display: flex;
						align-items: center;

						.right_day_box1_left {
							flex-shrink: 0;
							width: 159px;
							height: 90px;
							// border-radius: 16px;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 13px;

							img {
								width: 100%;
							}
						}

						.right_day_box1_right {
							flex: 1;
							height: 90px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.right_day_box1_right_one {
								font-size: 11px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #ffce00;
								-webkit-line-clamp: 1;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							.right_day_box1_right_two {
								font-size: 9px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #ffffff;
								line-height: 21px;
								-webkit-line-clamp: 2;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							.right_day_box1_right_three {
								width: 104px;
								height: 26px;
								line-height: 26px;
								text-align: center;
								background: linear-gradient(0deg, #085eaf, #2791f4);
								border-radius: 4px;
								font-size: 13px;
								font-family: Source Han Sans CN;
								font-weight: 500;
								color: #ffffff;
								cursor: pointer;
							}
						}
					}

					.right_day_box2 {
						width: 100%;
						display: flex;
						flex-direction: column;

						.right_day_box2_top {
							display: flex;
							align-items: center;
							margin-top: 15px;

							.right_day_box1_left {
								flex-shrink: 0;
								width: 84px;
								height: 46px;
								// border-radius: 16px;
								overflow: hidden;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 9px;

								img {
									width: 100%;
								}
							}

							.right_day_box1_right {
								flex: 1;
								height: 46px;
								display: flex;
								align-items: center;
								justify-content: space-between;

								.right_day_box1_right_info {
									flex: 1;
									display: flex;
									flex-direction: column;
									justify-content: space-evenly;

									.right_day_box1_right_one {
										font-size: 11px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #ffce00;
										-webkit-line-clamp: 1;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										overflow: hidden;
										text-overflow: ellipsis;
									}

									.right_day_box1_right_two {
										font-size: 9px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #ffffff;
										-webkit-line-clamp: 2;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										overflow: hidden;
										text-overflow: ellipsis;
									}
								}

								.right_day_box1_right_play {
									width: 18px;
									height: 18px;
									cursor: pointer;
									margin-left: 5px;

									img {
										width: 100%;
										height: 100%;
									}
								}
							}
						}
					}

					.right_day_x {
						width: 278px;
						height: 1px;
						margin: 10px 0 4px 0;
						border-bottom: 1px dashed #374969;
					}

					.right_day_list {
						width: 100%;

						.right_day_list_item {
							width: 100%;
							height: 21px;
							display: flex;
							align-items: center;
							padding: 0 5px 0 0;
							box-sizing: border-box;
							cursor: pointer;
							background-color: rgba(0, 0, 0, 0);
							transition: 0.3s;

							&:hover {
								background-color: rgba(255, 255, 255, 0.3) !important;

								.right_day_list_item_wz {
									transition: 0.3s;
									color: rgba(255, 255, 255, 1) !important;
								}
							}

							.right_day_list_item_tps {
								flex-shrink: 0;
								font-size: 12px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #e556ff;
							}

							.right_day_list_item_wz {
								flex: 1;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								-o-text-overflow: ellipsis;
								font-size: 13px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: rgba(132, 149, 179, 1);
							}

							.right_day_list_item_icon {
								width: 15px;
								height: 13px;
								margin-right: 9px;

								img {
									width: 100%;
									height: 100%;
								}
							}

							.right_day_list_item_play {
								flex-shrink: 0;
								width: 13px;
								height: 13px;
								margin-left: 5px;
							}
						}
					}
				}
			}
		}

		.layout_content_footer {
			width: 100%;
			height: 144px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			background-image: url("@/assets/footer@2x.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;

			.layout_content_footer_bottom {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 9px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #8fa2c2;

					&:nth-child(2) {
						margin-right: 20px;
						margin-left: 20px;
					}
				}
			}
		}
	}
}

.right_day_head_sou {
	margin-top: 10px;
	width: 100%;
	height: 40px;
	background-color: #ebf4fd;
	//background: rgba(50, 78, 125, 0.3);
	border-radius: 3px;
	padding: 4px 9px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;

	input::-webkit-input-placeholder {
		font-size: 15px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #8296b7;
	}

	input {
		width: 90px;
		height: 100%;
		outline: none;
		border: none;
		color: #fff;
		font-size: 15px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		background-color: rgba(0, 0, 0, 0);
	}

	img {
		flex-shrink: 0;
		width: 13px;
		height: 13px;
		cursor: pointer;
	}
}

.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	z-index: 998;
}

.close {
	position: absolute;
	top: 10px;
	right: 10%;
	color: white;
	font-size: 45px;
	cursor: pointer;
}

.textinfo {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.comment-box img {
	width: 100px;
}
</style>
